import React, { lazy, Suspense } from "react";
import "./App.scss";
import "./style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardWrapper from "./components/globalComponents/DashboardWrapper";
import Loading from "./utils/Loading";
import {
  BINGO_PAGE,
  DASHBOARD_PAGE,
  DUBSMASH_PAGE,
  EVENT_CREATE_PAGE,
  EVENT_DETAILS_PAGE,
  EVENT_SETTINGS_PAGE,
  EXPLORE_DETAILS_PAGE,
  EXPLORE_PAGE,
  FAQ_PAGE,
  FORGOT_PASSWORD,
  HELP_PAGE,
  HISTORY_DETAILS_PAGE,
  HISTORY_PAGE,
  KAHOOT_PAGE,
  LANDING_PAGE,
  LOGIN_PAGE,
  PRIVACY_POLICY,
  REGISTER_PAGE,
  SUBSCRIPTIONS_PAGE,
  TERMS_AND_CONDITIONS,
  TESTIMONIALS_PAGE,
  VERIFY_EMAIL_PAGE,
} from "./routes";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import Testimonials from "./view/Testimonials";
import LandingPage from "./view/LandingPage";

const Login = lazy(() => import("./view/Login"));
const Register = lazy(() => import("./view/Register"));
const ForgotPassword = lazy(() => import("./view/ForgotPassword"));
const PrivacyPolicy = lazy(() => import("./view/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("./view/TermsAndConditions"));
const EmailVerification = lazy(() => import("./view/EmailVerification"));
const Dashboard = lazy(() => import("./view/Dashboard"));
const Explore = lazy(() => import("./view/Explore"));
const ViewGame = lazy(() => import("./view/ViewGame"));
const Subscriptions = lazy(() => import("./view/Subscriptions"));
const History = lazy(() => import("./view/History"));
const HistoryDetails = lazy(() => import("./view/HistoryDetails"));
const EventSettings = lazy(() => import("./view/EventSettings"));
const CreateEvent = lazy(() => import("./view/Event/CreateEvent"));
const EventDetails = lazy(() => import("./view/EventDetails"));
const Help = lazy(() => import("./view/Help"));

const Kahoot = lazy(() => import("./view/Kahoot"));
const Dubsmash = lazy(() => import("./view/Dubsmash"));
const Bingo = lazy(() => import("./view/Bingo"));

function App() {
  const { hostInfo } = useSelector((state) => state.hostInfo);

  const routeCondition = hostInfo?.token;

  const PrivateRoute = ({ children }) => {
    return routeCondition ? (
      <DashboardWrapper>{children}</DashboardWrapper>
    ) : (
      <Navigate to={LOGIN_PAGE} />
    );
  };

  const PublicRoute = ({ children }) => {
    return routeCondition ? <Navigate to={DASHBOARD_PAGE} /> : children;
  };

  const GameRoute = ({ children }) => {
    return routeCondition ? children : <Navigate to={LOGIN_PAGE} />;
  };

  const privateRoutesList = [
    {
      path: DASHBOARD_PAGE,
      element: <Dashboard />,
    },
    {
      path: EXPLORE_PAGE,
      element: <Explore />,
    },
    {
      path: EXPLORE_DETAILS_PAGE,
      element: <ViewGame />,
    },
    {
      path: SUBSCRIPTIONS_PAGE,
      element: <Subscriptions />,
    },
    {
      path: HISTORY_PAGE,
      element: <History />,
    },
    {
      path: HISTORY_DETAILS_PAGE,
      element: <HistoryDetails />,
    },
    {
      path: EVENT_CREATE_PAGE,
      element: <CreateEvent />,
    },
    {
      path: EVENT_SETTINGS_PAGE,
      element: <EventSettings />,
    },
    {
      path: EVENT_DETAILS_PAGE,
      element: <EventDetails />,
    },
    {
      path: HELP_PAGE,
      element: <Help />,
    },
  ];

  const gamesRouteList = [
    {
      path: KAHOOT_PAGE,
      element: <Kahoot />,
    },
    {
      path: DUBSMASH_PAGE,
      element: <Dubsmash />,
    },
    {
      path: BINGO_PAGE,
      element: <Bingo />,
    },
  ];

  const publicRoutesList = [
    {
      path: LOGIN_PAGE,
      element: <Login />,
    },
    {
      path: FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: LANDING_PAGE,
      element: <LandingPage />,
    },
    {
      path: REGISTER_PAGE,
      element: <Register />,
    },
    {
      path: PRIVACY_POLICY,
      element: <PrivacyPolicy />,
    },
    {
      path: TERMS_AND_CONDITIONS,
      element: <TermsAndConditions />,
    },
    {
      path: VERIFY_EMAIL_PAGE,
      element: <EmailVerification />,
    },

    {
      path: TESTIMONIALS_PAGE,
      element: <Testimonials />,
    },
  ];

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {publicRoutesList.map(({ path, element }, idx) => (
            <Route
              path={path}
              element={<PublicRoute>{element}</PublicRoute>}
              key={idx}
            />
          ))}
          {privateRoutesList.map(({ path, element }, idx) => (
            <Route
              path={path}
              element={<PrivateRoute>{element}</PrivateRoute>}
              key={idx}
            />
          ))}
          {gamesRouteList.map(({ path, element }, idx) => (
            <Route
              path={path}
              element={<GameRoute>{element}</GameRoute>}
              key={idx}
            />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

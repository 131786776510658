import { createContext, useContext, useEffect, useState } from "react";
import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import "firebase/compat/firestore";
import { auth } from "./firebaseIndex";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const getIdTokenForUser = () => auth.currentUser.getIdToken();

  const signInWithEmailAndPassword = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });

    return auth.signInWithPopup(provider);
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();

    return auth.signInWithPopup(provider);
  };

  const getCurrentUser = () => {
    return auth.currentUser;
  };

  const isVerified = () => {
    let user = auth.currentUser;

    return user.emailVerified;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false);
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    getIdTokenForUser,
    signInWithEmailAndPassword,
    signInWithGoogle,
    signInWithFacebook,
    getCurrentUser,
    isVerified,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

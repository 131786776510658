export const LANDING_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const REGISTER_PAGE = "/signup";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const VERIFY_EMAIL_PAGE = "/verify-email";
export const DASHBOARD_PAGE = "/dashboard";
export const EXPLORE_PAGE = "/explore";
export const EXPLORE_DETAILS_PAGE = "/explore/:id";
export const SUBSCRIPTIONS_PAGE = "/subscription";
export const HISTORY_PAGE = "/history";
export const HISTORY_DETAILS_PAGE = "/history/:id";
export const EVENT_CREATE_PAGE = "/dashboard/event/create";
export const EVENT_DETAILS_PAGE = "/dashboard/event/:id";
export const EVENT_SETTINGS_PAGE = "/dashboard/event/:id/settings";
export const HELP_PAGE = "/help";
export const KAHOOT_PAGE = "/samaya-yantra/:id/:code";
export const DUBSMASH_PAGE = "/natakkhel/:id/:code";
export const BINGO_PAGE = "/bingo/:id/:code";
export const FAQ_PAGE = "/faq";
export const TESTIMONIALS_PAGE = "/testimonials";
